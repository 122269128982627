import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGridPro, GridRow } from '@mui/x-data-grid-pro';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { darken, lighten, styled } from '@mui/material/styles';
import {
  formatToUTCDate,
  formatToUTCDateTime,
  formatDate,
  formatDateTime,
} from '@config/functions/helperFunctions';
import EditableTableLink from '@components/EditableTableComps/EditableTableLink';
import ClickToDial from '@components/CRM/ClickToDial';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { getColumns } from '@config/metaMappings';
import MenuItems from '@components/core/MenuItems';
import {
  EditableAutocompleteV2ReadOnly,
  EditableAutocompleteV2,
} from '@components/EditableTableComps/EditableAutocompleteV2';
import { renderAutocomplete } from '@components/EditableTableComps/EditableAutocomplete';
import {
  renderRepeat,
  RepeatEditInputCell,
} from '@components/EditableTableComps/EditableRepeat';
import DateTime from '@components/DateTime';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { AutocompleteEditInputCell } from '@components/EditableTableComps/EditableAutocomplete';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDataGrid-row.Mui-even:not(:hover)': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

function CustomRow(props) {
  const { className, index, ...other } = props;

  return <GridRow index={index} {...other} />;
}

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--lightRedBerry': {
    backgroundColor: getBackgroundColor(
      theme.palette.lightRedBerry.main,
      theme.palette.mode
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.lightRedBerry.main,
        theme.palette.mode
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.lightRedBerry.main,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.lightRedBerry.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--lightRed': {
    backgroundColor: getBackgroundColor(
      theme.palette.lightRed.main,
      theme.palette.mode
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.lightRed.main,
        theme.palette.mode
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.lightRed.main,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.lightRed.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--lightOrange': {
    backgroundColor: getBackgroundColor(
      theme.palette.lightOrange.main,
      theme.palette.mode
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.lightOrange.main,
        theme.palette.mode
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.lightOrange.main,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.lightOrange.main,
          theme.palette.mode
        ),
      },
    },
  },

  '& .super-app-theme--lightYellow': {
    backgroundColor: getBackgroundColor(
      theme.palette.lightYellow.main,
      theme.palette.mode
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.lightYellow.main,
        theme.palette.mode
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.lightYellow.main,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.lightYellow.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--lightGreen': {
    backgroundColor: getBackgroundColor(
      theme.palette.lightGreen.main,
      theme.palette.mode
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.lightGreen.main,
        theme.palette.mode
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.lightGreen.main,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.lightGreen.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--lightCyan': {
    backgroundColor: getBackgroundColor(
      theme.palette.lightCyan.main,
      theme.palette.mode
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.lightCyan.main,
        theme.palette.mode
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.lightCyan.main,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.lightCyan.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--lightCornflower': {
    backgroundColor: getBackgroundColor(
      theme.palette.lightCornflower.main,
      theme.palette.mode
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.lightCornflower.main,
        theme.palette.mode
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.lightCornflower.main,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.lightCornflower.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--lightBlue': {
    backgroundColor: getBackgroundColor(
      theme.palette.lightBlue.main,
      theme.palette.mode
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.lightBlue.main,
        theme.palette.mode
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.lightBlue.main,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.lightBlue.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--lightPurple': {
    backgroundColor: getBackgroundColor(
      theme.palette.lightPurple.main,
      theme.palette.mode
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.lightPurple.main,
        theme.palette.mode
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.lightPurple.main,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.lightPurple.main,
          theme.palette.mode
        ),
      },
    },
  },

  '& .super-app-theme--lightMagenta': {
    backgroundColor: getBackgroundColor(
      theme.palette.lightMagenta.main,
      theme.palette.mode
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.lightMagenta.main,
        theme.palette.mode
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.lightMagenta.main,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.lightMagenta.main,
          theme.palette.mode
        ),
      },
    },
  },
}));

function renderAutocompleteEditInputCell(params) {
  return <AutocompleteEditInputCell {...params} />;
}

const makeModificationsToColumns = (
  columns,
  hideActions,
  handleDelete,
  rowActions,
  visibility,
  setShowVisibilityModal,
  setSelectedRow,
  user,
  showDeleteBtn,
  deleteBtnLabel,
  dynamicActions
) => {
  const newColumns = columns
    .filter((column) => !column.hideColumn)
    .map((column) => {
      if (column.type === 'order') {
        column.renderCell = (params) => (
          <span>
            {params?.row?.task_level ?? ''}
            {params?.row?.stage_full_order ?? ''}
            {params?.row?.order ?? ''}
          </span>
        );
        column.type = 'string';
        column.actualType = 'order';
        delete column.preProcessEditCellProps;
      }
      if (column.type === 'autocompleteV2') {
        column.renderCell = (params) => (
          <EditableAutocompleteV2ReadOnly params={params} />
        );
        column.renderEditCell = (params) => (
          <EditableAutocompleteV2 params={params} />
        );
        column.type = 'string';
        column.actualType = 'autocompleteV2';
        delete column.preProcessEditCellProps;
      } else if (column.type === 'autocomplete') {
        column.renderCell = column.renderCell || renderAutocomplete;
        column.renderEditCell =
          column.renderEditCell || renderAutocompleteEditInputCell;
        column.type = 'string';
        column.actualType = 'autocomplete';
        delete column.preProcessEditCellProps;
      } else if (column.type === 'phone') {
        const phoneRenderCell = (params) => {
          return (
            <ClickToDial
              phone={params?.value}
              microservice={column.microservice}
              model={column.model}
              recordId={params?.row?.id}
            />
          );
        };

        column.renderCell = column.renderCell || phoneRenderCell;
        column.type = 'string';
        column.actualType = 'link';
      } else if (column.type === 'link' || column.to || column.getHref) {
        const linkRenderCell = (params) => {
          return <EditableTableLink params={params} />;
        };

        column.renderCell = column.renderCell || linkRenderCell;
        column.type = 'string';
        column.actualType = 'link';
      } else if (column.type === 'audio') {
        const audioRenderCell = (params) => {
          return (
            <Box>
              {params.value ? (
                <audio controls>
                  <source
                    src={`${params.value}?access_token=Bearer ${user?.token}`}
                    type="audio/wav"
                  />
                  Your browser does not support the audio element.
                </audio>
              ) : (
                '...'
              )}
            </Box>
          );
        };

        column.renderCell = column.renderCell || audioRenderCell;
      } else if (column.type === 'date') {
        const dateRenderCell = (params) => {
          return (
            <Typography component="div" variant="p">
              {!params.value ? '...' : formatDate(params.value)}
            </Typography>
          );
        };

        const dateRenderEditCell = (props) => {
          const { id, value, api, field, colDef } = props;
          return (
            <DateTime
              label={colDef.field}
              data={value}
              handleSubmit={async (value) => {
                if (!value) return;
                api.setEditCellValue({
                  id,
                  field,
                  value: formatToUTCDate(value.dateObject),
                });
                await api.commitCellChange({ id, field });
                api.setCellMode(id, field, 'view');
              }}
            />
          );
        };

        column.renderCell = column.renderCell || dateRenderCell;

        column.renderEditCell = column.renderEditCell || dateRenderEditCell;
      } else if (column.type === 'repeat') {
        column.renderCell = column.renderCell || renderRepeat;
        column.renderEditCell = column.renderEditCell || RepeatEditInputCell;
        column.type = 'string';
        column.actualType = 'repeat';
        delete column.preProcessEditCellProps;
      } else if (column.type === 'singleSelect') {
        column.valueOptions = column.options;
        delete column.preProcessEditCellProps;
      } else if (column.type === 'dateTime') {
        delete column.preProcessEditCellProps;

        const dateTimeRenderCell = (params) => {
          return (
            <Typography component="div" variant="p">
              {!params.value ? '...' : formatDateTime(params.value)}
            </Typography>
          );
        };

        const dateTimeRenderEditCell = (props) => {
          const { id, value, api, field, colDef } = props;
          return (
            <DateTime
              showTimezone={colDef?.showTimezone ?? false}
              showTime
              label={colDef.field}
              data={value}
              handleSubmit={async (value) => {
                api.setEditCellValue({
                  id,
                  field,
                  value: formatToUTCDateTime(new Date(value.dateObject)),
                });
                await api.commitCellChange({ id, field });
                api.setCellMode(id, field, 'view');
              }}
            />
          );
        };

        column.renderCell = column.renderCell || dateTimeRenderCell;
        column.renderEditCell = column.renderEditCell || dateTimeRenderEditCell;
      }

      return column;
    });

  if (!hideActions) {
    const newRowActions = [...rowActions];

    if (handleDelete && showDeleteBtn) {
      newRowActions.unshift({
        icon: <DeleteIcon sx={{ mr: 1 }} />,
        label: deleteBtnLabel ?? 'Move to trash',
        color: '#ff1a1a',
        handleClick: handleDelete,
      });
    }

    if (visibility) {
      const handleVisibility = (row) => {
        setShowVisibilityModal(true);
        setSelectedRow(row);
      };

      newRowActions.unshift({
        icon: <HttpsOutlinedIcon sx={{ mr: 1 }} />,
        label: 'Visibility',
        color: '#808080',
        handleClick: (row) => () => handleVisibility(row),
      });
    }

    newColumns.push({
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (props) => {
        const actions = [...newRowActions, ...dynamicActions(props)];

        return [
          <MenuItems key="Menu Items" context={props} actions={actions} />,
        ];
      },
    });
  }

  return newColumns;
};

export default function EditableTable({
  rows,
  apiRef,
  handleUpdate,
  pageSize,
  setPageSize,
  rowsPerPageOptions,
  currentPage,
  onPageChange,
  totalItems,
  selectedRows,
  setSelectedRows,
  isCellEditable,
  loading,
  sortingMode,
  sortModel,
  onSortModelChange,
  components,
  getDetailPanelContent,
  autoHeight,
  refetch,
  rowCount,
  noFullDataSetSelect,
  columnKey,
  columnOptions,
  hideActions,
  actions,
  rowActions,
  visibility,
  setShowVisibilityModal,
  setSelectedRow,
  user,
  showDeleteBtn,
  deleteBtnLabel,
  dynamicActions,
  checkboxSelection = true,
}) {
  const styles = useStyles();

  const columnsMemoised = React.useMemo(
    () => getColumns(columnKey, columnOptions),
    [columnKey, columnOptions]
  );

  const columnsRef = React.useRef(columnsMemoised);

  React.useEffect(() => {
    if (columnsRef.current !== columnsMemoised) {
      console.log('The columns array reference is new.', Date.now());
    } else {
      console.log('The columns array reference is the same.');
    }

    // Update the ref with the current columns array reference
    columnsRef.current = columnsMemoised;
  }, [columnsMemoised]);

  const newColumns = React.useMemo(() => {
    return makeModificationsToColumns(
      columnsMemoised,
      hideActions,
      actions.handleDelete,
      rowActions,
      visibility,
      setShowVisibilityModal,
      setSelectedRow,
      user,
      showDeleteBtn,
      deleteBtnLabel,
      dynamicActions
    );
  }, []);

  return (
    <Box style={{ position: 'relative' }}>
      <Box
        sx={{
          height: autoHeight || '82vh',
          backgroundColor: 'white',
          width: '100%',
          '& .MuiDataGrid-virtualScroller': {
            background: 'white',
          },
          '& .disable-row': {
            color: 'text.secondary',
            fontStyle: 'italic',
            '& .MuiLink-root': {
              color: 'text.secondary',
              pointerEvents: 'none',
              cursor: 'default',
              textDecoration: 'none',
            },
          },
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
          '& .even': {
            bgcolor: '#F6F6F6',
          },
          '& .even:hover': {
            bgcolor: '#F6F6F6 !important',
          },
        }}
        className={styles.root}
      >
        <StyledDataGridPro
          density="compact"
          rows={rows}
          columns={newColumns}
          apiRef={apiRef}
          onCellEditCommit={(params, event) =>
            handleUpdate(params, event, refetch)
          }
          checkboxSelection={checkboxSelection}
          disableSelectionOnClick
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={rowsPerPageOptions}
          page={currentPage}
          onPageChange={onPageChange}
          rowCount={rowCount}
          pagination
          onSelectionModelChange={(newSelectionModel) => {
            if (newSelectionModel.length > pageSize) {
              setSelectedRows(newSelectionModel.slice(pageSize * currentPage));
            } else {
              setSelectedRows(newSelectionModel);
            }
          }}
          // getRowHeight={() => 'auto'}
          // autosizeOptions={{
          //   includeOutliers: true,
          // }}
          selectionModel={selectedRows}
          isCellEditable={isCellEditable}
          loading={loading}
          sortingOrder={['desc', 'asc']}
          sortingMode={sortingMode}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          components={{ ...components, Row: CustomRow }}
          // treeData
          // getTreeDataPath={(row) => row.path}
          getDetailPanelContent={getDetailPanelContent}
          // autoHeight={autoHeight}
          rowBuffer={+pageSize * 2 || 20}
          hideFooterSelectedRowCount
          // sx={{
          //   [`& .${gridClasses.cell}`]: {
          //     py: 0.5,
          //   },
          // }}
          // {...rest}
        />
      </Box>

      {Array.isArray(selectedRows) && selectedRows.length ? (
        <Typography
          sx={{
            position: 'absolute',
            top: '95%',
            left: '1%',
            fontSize: '14px',
          }}
        >
          {!noFullDataSetSelect && selectedRows.length === rows.length
            ? rowCount
            : selectedRows.length}{' '}
          rows selected
        </Typography>
      ) : null}
    </Box>
  );
}
