import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, IconButton, MenuItem, Typography } from '@mui/material';
import { Box, Autocomplete, Chip } from '@mui/material';
import MUITextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import ClearIcon from '@mui/icons-material/Clear';
import { selectProfile } from '@redux/profileSlice';
import {
  updateRecord,
  deleteRecord,
  createRecord,
} from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { calendarUrls, accountUrls } from '@config/routes';
import ErrorScreen from '@components/ErrorScreen';
import {
  TextField,
  DateTimePickerField,
  SelectField,
  AutocompleteField,
  CheckBoxField,
} from '@components/Inputs';
import Loader from '@components/Loader';
import DetailPageHeader from '@components/DetailPageHeader';
import Button from '@ui/Button';
import {
  calendarNotificationDayChoicesOptions,
  calendarNotificationTypeOptions,
  calendarRecurringChoicesOptions,
  DAILY_RRULE,
  WEEKLY_RRULE,
  MONTHLY_RRULE,
  ANNUALLY_RRULE,
  WEEKDAYS_RRULE,
  eventTypeChoices,
} from '@config/constants';
import {
  uploadFilesToDrive,
  validateEmail,
} from '@config/functions/helperFunctions';
import { detectRrule } from '@config/functions/calendarFunctions';
import CustomRecurranceModal from '@components/Calendar/CustomRecurranceModal';
import SendEmailModal from '@components/Calendar/SendEmailModal';
import UpdateTypeModal from '@components/Calendar/UpdateTypeModal';
import useAutocomplete from '@hooks/useAutocomplete';
import { useDropzone } from 'react-dropzone';
import useRQuery from '@hooks/useRQuery';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const {
  eventsUrls,
  guestsUrls,
  notificationsUrls,
  attachmentsUrls,
  calendarAccountsUrls,
  calendarsUrls,
} = calendarUrls;
const { usersUrls } = accountUrls;

export default function EventDetails({ labels, editMode }) {
  const { id: recordId } = useParams();
  const navigate = useNavigate();
  const user = useSelector(selectProfile);
  const [event, setEvent] = useState();
  const [users, setUsers] = React.useState([]);
  const [repeatInitialChoice, setRepeatInitialChoice] = React.useState('');
  const [rrule, setRRule] = React.useState('');
  const [updateType, setUpdateType] = React.useState('this_event');
  const [modal, setModal] = React.useState(false);
  const [sendEmailModal, setSendEmailModal] = React.useState(false);
  const [showThisEventOption, setShowThisEventOption] = React.useState(true);
  const [showAllEventsOption, setShowAllEventsOption] = React.useState(true);
  const [updateTypeModalSubmitted, setUpdateTypeModalSubmitted] =
    React.useState(false);
  const [updateTypeModalOpen, setUpdateTypeModalOpen] = React.useState(false);
  const [notify] = useToast();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [attachments, setAttachments] = useState([]);
  const [calendarAccountTerm, setCalendarAccountTerm] = React.useState('');
  const [calendarTerm, setCalendarTerm] = React.useState('');
  const [selectedCalendarAccount, setSelectedCalendarAccount] =
    React.useState(null);
  const [selectedCalendar, setSelectedCalendar] = React.useState(null);

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  const { data, isError, refetch } = useRQuery({
    key: ['event', recordId, user.token, user?.actAs],
    url: eventsUrls.detail(recordId),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!(user && user.token) },
  });

  const { data: calendarAccounts, isFetching: fetchingCalendarAccounts } =
    useAutocomplete({
      key: 'calendar-accounts',
      getUrl: calendarAccountsUrls.list,
      inputValue: calendarAccountTerm,
      selected: selectedCalendarAccount,
      renderRow: (r) => ({
        id: r.id,
        label: r.email,
      }),
    });

  const { data: calendars, isFetching: fetchingCalendars } = useAutocomplete({
    key: ['calendars', selectedCalendarAccount],
    getUrl: calendarsUrls.list,
    query: `&account=${
      (selectedCalendarAccount && selectedCalendarAccount.id) || ''
    }`,
    inputValue: calendarTerm,
    enabled: !!selectedCalendarAccount,
  });

  const { data: usersList } = useRQuery({
    key: ['users-data', user.token, user?.actAs],
    url: usersUrls.list(`?page_size=100&queryCode=9827`),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  React.useEffect(() => {
    if (!setAttachments) return;
    setAttachments(acceptedFiles);
  }, [acceptedFiles, setAttachments]);

  React.useEffect(() => {
    if (!usersList) return;
    if (!usersList.results) return;

    setUsers(usersList.results.map((item) => item.email));
  }, [usersList]);

  React.useEffect(() => {
    if (!data) return;

    const parentRruleChoiceOption =
      data.parent_event_details && data.parent_event_details.rrule
        ? detectRrule(data.parent_event_details.rrule)
        : calendarRecurringChoicesOptions[0];
    const rruleChoiceOption = data.rrule
      ? detectRrule(data.rrule)
      : calendarRecurringChoicesOptions[0];
    let newRruleChoiceOption = rruleChoiceOption;

    if (
      rruleChoiceOption &&
      rruleChoiceOption.value === calendarRecurringChoicesOptions[0].value &&
      parentRruleChoiceOption &&
      parentRruleChoiceOption.value !== calendarRecurringChoicesOptions[0].value
    ) {
      newRruleChoiceOption = parentRruleChoiceOption;
    }

    setRepeatInitialChoice(newRruleChoiceOption.value);

    if (
      newRruleChoiceOption.value === calendarRecurringChoicesOptions[6].value
    ) {
      // CUSTOM
      setRRule(
        data.rrule ||
          (data.parent_event_details &&
            data.parent_event_details.rrule &&
            data.parent_event_details.rrule)
      );
    }

    setSelectedCalendarAccount({
      id: data.calendar_details.account_id,
      label: data.calendar_account_details.email,
    });

    setSelectedCalendar({
      id: data.calendar,
      label: data.calendar_details.name,
    });

    setEvent({
      ...data,
    });
  }, [data]);

  if (isError) {
    return <ErrorScreen error="Failed to fetch the resource from server" />;
  }

  const handleGuestDelete = async (id) => {
    try {
      await deleteRecord({
        url: guestsUrls.detail(id),
        token: user.token,
        actAs: user?.actAs,
      });
      setEvent({
        ...event,
        guests: event.guests.filter((guest) => guest.id !== id),
      });
      notify(`Guest Deleted!`, {
        type: 'SUCCESS',
      });
    } catch (err) {
      console.log(err);
      notify(`There was an error, please try again.`, {
        type: 'ERROR',
      });
    }
  };

  const handleAttachmentDelete = async (id) => {
    try {
      await deleteRecord({
        url: attachmentsUrls.detail(id),
        token: user.token,
        actAs: user?.actAs,
      });
      setEvent({
        ...event,
        attachments: event.attachments.filter(
          (attachment) => attachment.id !== id
        ),
      });
      notify('Attachment Deleted!', {
        type: 'SUCCESS',
      });
    } catch (err) {
      console.log(err);
      notify('There was an error, please try again.', {
        type: 'ERROR',
      });
    }
  };

  const handleSendEmail = async (emails, guests) => {
    setSendEmailModal(false);
    try {
      emails.map(async (email) => {
        const findValue = guests.find((element) => {
          return element.label === email;
        });
        if (!findValue) {
          const guestValue = {
            email,
            event: recordId,
          };

          await createRecord({
            values: guestValue,
            url: guestsUrls.list(),
            token: user.token,
            actAs: user?.actAs,
          });
        }
      });
      refetch();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      {event && calendars && calendars.length ? (
        <>
          <DetailPageHeader
            key="detail-view-header"
            items={[
              { to: `/calendar/${event.calendar}`, text: 'Calendar' },
              { text: event && event.title },
            ]}
          />
          <Formik
            initialValues={{
              title: (event && event.title) || '',
              description: (event && event.description) || '',
              location: (event && event.location) || '',
              link: (event && event.link) || '',
              start_date_time: new Date(event?.start_date_time),
              end_date_time: new Date(event?.end_date_time),
              guests: [],
              slot_duration: event && event.slot_duration,
              buffer_between_slots: event && event.buffer_between_slots,
              duration:
                event && event.notifications && event.notifications.length
                  ? event.notifications[0].duration
                  : 30,
              type:
                event && event.notifications && event.notifications.length
                  ? event.notifications[0].type
                  : calendarNotificationTypeOptions[0].value,
              unit:
                event && event.notifications && event.notifications.length
                  ? event.notifications[0].unit
                  : calendarNotificationDayChoicesOptions[2].value,
              all_day: event && event.all_day,
              rrule: event && event.all_day,
              recurring: repeatInitialChoice,
              calendar: selectedCalendar && selectedCalendar.id,
            }}
            validationSchema={Yup.object({
              title: Yup.string().max(150).required(),
              location: Yup.string().max(150),
              link: Yup.string().url(),
              description: Yup.string().max(2500),
              start_date_time: Yup.date()
                .default(() => new Date())
                .required(),
              end_date_time: Yup.date()
                .default(() => new Date())
                .required(),
              guests: Yup.array(),
              duration: Yup.number().required(),
              slot_duration: Yup.number().min(0),
              buffer_between_slots: Yup.number().min(0),
              rrule: Yup.string(),
              type: Yup.string().required(),
              unit: Yup.string().required(),
              all_day: Yup.boolean(),
              recurring: Yup.string(),
              calendar: Yup.string().required(),
            })}
            onSubmit={async (
              values,
              { resetForm, setSubmitting },
              setError
            ) => {
              try {
                let newRrule = rrule;

                if (values.recurring === 'does_not_repeat') {
                  newRrule = '';
                } else if (values.recurring === 'daily') {
                  newRrule = DAILY_RRULE;
                } else if (values.recurring === 'weekly') {
                  newRrule = WEEKLY_RRULE;
                } else if (values.recurring === 'monthly') {
                  newRrule = MONTHLY_RRULE;
                } else if (values.recurring === 'annually') {
                  newRrule = ANNUALLY_RRULE;
                } else if (values.recurring === 'weekdays') {
                  newRrule = WEEKDAYS_RRULE;
                } else {
                  newRrule = rrule;
                }

                if (
                  ((event.rrule && event.rrule.length) || event.parent_event) &&
                  event.parent_event_details.event_type !== 'SlotEvent' &&
                  !updateTypeModalSubmitted
                ) {
                  if (
                    (event.rrule && newRrule !== event.rrule) ||
                    (event.parent_event_details.rrule &&
                      newRrule !== event.parent_event_details.rrule)
                  ) {
                    setUpdateType('this_and_following_events');
                    setShowThisEventOption(false);
                  }

                  if (
                    new Date(event.start_date_time).toDateString() !==
                      new Date(values.start_date_time).toDateString() ||
                    new Date(event.end_date_time).toDateString() !==
                      new Date(values.end_date_time).toDateString()
                  ) {
                    setUpdateType('this_event');
                    setShowAllEventsOption(false);
                  } else if (!showAllEventsOption) {
                    setShowAllEventsOption(true);
                  }

                  return setUpdateTypeModalOpen(true);
                }

                const newValues = {
                  ...values,
                  start_date_time: new Date(values.start_date_time),
                  end_date_time:
                    event.event_type === eventTypeChoices[2].value
                      ? null
                      : new Date(values.end_date_time),
                };

                delete newValues.rrule;

                if (
                  (event.rrule && newRrule !== event.rrule) ||
                  (event.parent_event_details.rrule &&
                    newRrule !== event.parent_event_details.rrule) ||
                  (!event.rrule && !event.parent_event && newRrule)
                ) {
                  newValues.rrule = newRrule;
                }

                if ((event.rrule && event.rrule.length) || event.parent_event) {
                  newValues.update_type = updateType;
                }

                if (attachments.length) {
                  const { urls } = await uploadFilesToDrive({
                    files: attachments,
                    userToken: user.token,
                  });

                  if (urls.filter((url) => url.error).length) {
                    notify(`There was an error, please try again.`, {
                      type: 'ERROR',
                    });
                    return;
                  }
                  urls.map(async (url) => {
                    const attachment = {
                      name: url.name,
                      attachment: url.url,
                      event: recordId,
                    };

                    try {
                      await createRecord({
                        values: attachment,
                        url: attachmentsUrls.list(),
                        token: user.token,
                        actAs: user?.actAs,
                      });
                    } catch (e) {
                      notify(`There was an error, please try again.`, {
                        type: 'ERROR',
                      });
                      setUpdateTypeModalSubmitted(false);
                      return;
                    }
                  });
                }

                if (values.guests) {
                  values.guests.map(async (email) => {
                    const guestValue = {
                      email: email,
                      event: recordId,
                    };

                    try {
                      await createRecord({
                        values: guestValue,
                        url: guestsUrls.list(),
                        token: user.token,
                        actAs: user?.actAs,
                      });
                    } catch (e) {
                      console.log(e);
                    }
                  });
                }

                const notificationValue = {
                  event: recordId,
                  duration: values.duration,
                  unit: values.unit,
                  type: values.type,
                };

                if (event.notifications.length) {
                  await updateRecord({
                    values: notificationValue,
                    url: notificationsUrls.detail(event.notifications[0].id),
                    token: user.token,
                    actAs: user?.actAs,
                  });
                } else {
                  await createRecord({
                    values: notificationValue,
                    url: notificationsUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });
                }

                await updateRecord({
                  values: newValues,
                  url: eventsUrls.detail(event.id),
                  token: user.token,
                  actAs: user?.actAs,
                });

                refetch();
                setAttachments([]);
                resetForm();
                notify(`Event Updated!`, {
                  type: 'SUCCESS',
                });
                navigate(`/calendar/${event.calendar}`);
                setUpdateTypeModalSubmitted(false);
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
                setUpdateTypeModalSubmitted(false);
              }
              setSubmitting(false);
            }}
          >
            {({
              isSubmitting,
              values,
              setFieldValue,
              errors,
              touched,
              handleSubmit,
            }) => (
              <Form noValidate autoComplete="off">
                <Grid
                  key="event-grid-container"
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{ mb: 2 }}
                >
                  <Grid item xs={12}>
                    <TextField name="title" label="Title" required />
                  </Grid>

                  <Grid
                    item
                    xs={event.event_type === eventTypeChoices[2].value ? 12 : 6}
                  >
                    {values.all_day ? (
                      <DateTimePickerField
                        name="start_date_time"
                        label="Start Date"
                        required
                      />
                    ) : (
                      <DateTimePickerField
                        name="start_date_time"
                        label="Start Date Time"
                        required
                        showTime
                      />
                    )}
                  </Grid>

                  {event.event_type === eventTypeChoices[2].value ? null : (
                    <Grid item xs={6}>
                      {values.all_day ? (
                        <DateTimePickerField
                          name="end_date_time"
                          label="End Date"
                          required
                        />
                      ) : (
                        <DateTimePickerField
                          name="end_date_time"
                          label="End Date Time"
                          required
                          showTime
                        />
                      )}
                    </Grid>
                  )}

                  {event.event_type === eventTypeChoices[1].value ? (
                    <>
                      <Grid item xs={4}>
                        <TextField
                          name="slot_duration"
                          label="Slot Duration In Minutes"
                          required
                          number
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <TextField
                          name="buffer_between_slots"
                          label="Buffer Between Slots In Minutes"
                          required
                          number
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={2}>
                        <CheckBoxField name="all_day" label="All Day" />
                      </Grid>
                    </>
                  )}

                  <Grid
                    item
                    xs={event.event_type === eventTypeChoices[1].value ? 4 : 10}
                  >
                    <SelectField
                      key="recurring-select-field"
                      label="Recurring"
                      name="recurring"
                      onClose={(e) =>
                        e.target.dataset.value === 'custom' && setModal(true)
                      }
                    >
                      {calendarRecurringChoicesOptions &&
                        calendarRecurringChoicesOptions.map((o, oIdx) => (
                          <MenuItem value={o.value} key={oIdx.toString()}>
                            {o.label}
                          </MenuItem>
                        ))}
                    </SelectField>
                  </Grid>

                  <Grid item xs={6}>
                    <AutocompleteField
                      label="Calendar Accounts"
                      options={calendarAccounts || []}
                      name="calendar_account"
                      optionLabel="label"
                      isLoading={fetchingCalendarAccounts}
                      value={selectedCalendarAccount}
                      setValue={(v) => setSelectedCalendarAccount(v)}
                      setSearchTerm={(v) => setCalendarAccountTerm(v)}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AutocompleteField
                      label="Calendar"
                      options={calendars || []}
                      name="calendar"
                      optionLabel="label"
                      value={selectedCalendar}
                      isLoading={fetchingCalendars}
                      setValue={(v) => {
                        setSelectedCalendar(v);
                      }}
                      setSearchTerm={(v) => setCalendarTerm(v)}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField name="link" label="Link" />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField name="location" label="Location" />
                  </Grid>

                  <Grid item xs={4}>
                    <SelectField
                      key="type-select-field"
                      label="Type"
                      name="type"
                      required
                    >
                      {calendarNotificationTypeOptions &&
                        calendarNotificationTypeOptions.map((o, oIdx) => (
                          <MenuItem value={o.value} key={oIdx.toString()}>
                            {o.label}
                          </MenuItem>
                        ))}
                    </SelectField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField name="duration" label="Duration" required />
                  </Grid>

                  <Grid item xs={4}>
                    <SelectField
                      key="day-choices-select-field"
                      label="Duration Unit"
                      name="unit"
                      required
                    >
                      {calendarNotificationDayChoicesOptions &&
                        calendarNotificationDayChoicesOptions.map((o, oIdx) => (
                          <MenuItem value={o.value} key={oIdx.toString()}>
                            {o.label}
                          </MenuItem>
                        ))}
                    </SelectField>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Description"
                      multiline
                      rows={6}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      options={users || []}
                      value={values.guests}
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        if (
                          newValue
                            .map((email) => validateEmail(email))
                            .some((t) => !t)
                        )
                          return;
                        setFieldValue('guests', newValue);
                      }}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            size="small"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <MUITextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          error={errors.guests && touched.guests}
                          helperText={errors.guests}
                          size="small"
                          label="Add Guests"
                          required
                          autoComplete="off"
                        />
                      )}
                    />
                  </Grid>
                  {event.guests && event.guests.length ? (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid
                          xs={11}
                          sx={{ display: 'flex', flexWrap: 'wrap', mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            {event.guests.length} Guests
                          </Typography>
                        </Grid>
                        {event.guests.map((guest) => {
                          return (
                            <>
                              <Grid
                                item
                                xs={11}
                                sx={{ display: 'flex', flexWrap: 'wrap' }}
                              >
                                <Typography
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {guest.first_name || guest.last_name
                                    ? `${guest.first_name || ''} ${
                                        guest.last_name || ''
                                      } -- `
                                    : ''}{' '}
                                  {guest.email}
                                </Typography>
                              </Grid>
                              <Grid item xs={1}>
                                <Box sx={{ display: 'flex' }}>
                                  <Box sx={{ ml: 'auto' }}>
                                    {user.details.email !== guest.email ? (
                                      <IconButton
                                        onClick={() =>
                                          handleGuestDelete(guest.id)
                                        }
                                        color="error"
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    ) : null}
                                  </Box>
                                </Box>
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                  ) : null}

                  {event.guests && event.guests.length ? (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid
                          xs={11}
                          sx={{ display: 'flex', flexWrap: 'wrap', mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            {event.attachments.length} Attachments
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: '1px dashed #c1c7d0',
                              p: '8px',
                              borderRadius: '4px',
                            }}
                          >
                            <div {...getRootProps({ className: 'dropzone' })}>
                              <input {...getInputProps()} />
                              <Typography
                                sx={{
                                  cursor: 'pointer',
                                  userSelect: 'none',
                                  color: '#7a869a',
                                }}
                              >
                                Drop Document to attach, or{' '}
                                <Typography
                                  component="span"
                                  sx={{ color: 'blue' }}
                                >
                                  browse
                                </Typography>
                              </Typography>
                            </div>
                          </Box>
                          <Box>
                            <ul style={{ padding: '0 0 0 20px' }}>{files}</ul>
                          </Box>
                        </Grid>
                        {event.attachments.map((attachment) => {
                          return (
                            <>
                              <Grid
                                item
                                xs={11}
                                sx={{ display: 'flex', flexWrap: 'wrap' }}
                              >
                                <a
                                  key="slot-link"
                                  href={`${attachment.attachment}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Typography
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {attachment.name}
                                  </Typography>
                                </a>
                              </Grid>
                              <Grid item xs={1}>
                                <Box sx={{ display: 'flex' }}>
                                  <Box sx={{ ml: 'auto' }}>
                                    <IconButton
                                      onClick={() =>
                                        handleAttachmentDelete(attachment.id)
                                      }
                                      color="error"
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Button
                      key="calendar-detail-cancel-button"
                      type="button"
                      variant="outlined"
                      label="Cancel"
                      onClick={() => navigate(`/calendar/${event.calendar}`)}
                      sx={{ ml: '5px' }}
                    />
                    <Button
                      key="calendar-detail-save-button"
                      type="submit"
                      variant="contained"
                      label="Save"
                      disabled={isSubmitting}
                      sx={{ ml: '5px' }}
                    />
                  </Grid>
                </Grid>
                <UpdateTypeModal
                  disabled={isSubmitting}
                  showThisEventOption={showThisEventOption}
                  showAllEventsOption={showAllEventsOption}
                  open={updateTypeModalOpen}
                  setOpen={setUpdateTypeModalOpen}
                  updateType={updateType}
                  setUpdateType={setUpdateType}
                  onSaveClick={() => {
                    setUpdateTypeModalSubmitted(true);
                    handleSubmit();
                  }}
                  prefix="03i"
                  labels={labels}
                  editMode={editMode}
                />
              </Form>
            )}
          </Formik>
          <CustomRecurranceModal
            open={modal}
            setOpen={setModal}
            oSave={(rrule) => {
              setRRule(rrule);
              setModal(false);
            }}
            rrule={rrule}
            prefix="02u"
            labels={labels}
            editMode={editMode}
          />
          <SendEmailModal
            key="send-email-event-dialog"
            open={sendEmailModal}
            setOpen={setSendEmailModal}
            onSendButtonClick={handleSendEmail}
            data={{ guests: event.guests, event }}
            prefix="0fw"
            labels={labels}
            editMode={editMode}
          />
        </>
      ) : (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      )}
    </Box>
  );
}
