import * as React from 'react';
import { Stack, Typography } from '@mui/material';
import { bpaUrls } from '@config/routes';
import useRQuery from '@hooks/useRQuery';
import Skeleton from '@mui/material/Skeleton';
import { getAuthHeader } from '@config/functions/helperFunctions';
// import SidebarMoreBtn from '@components/BPA-V2/Sidebar/SidebarMoreBtn';
import ShapeDetailsForm from '@components/BPA-V2/ShapeDetailsForm';
import ShapeActionTypeList from '@components/BPA-V2/ShapeActions/ShapeActionTypeList';
import TerminatorActions from '@components/BPA-V2/ShapeActions/TerminatorActions';
import DelayActions from '@components/BPA-V2/ShapeActions/DelayActions';

const { getShapeWithShapeActionsUrls } = bpaUrls;

export default function ShapeSidebar({
  user,
  graph,
  selectedShape,
  workflowId,
  workflowName,
}) {
  const { data, isFetching, isError } = useRQuery({
    key: [
      `${selectedShape?.id}-shape-and-actions`,
      selectedShape?.id,
      user.token,
      user?.actAs,
    ],
    url: getShapeWithShapeActionsUrls.detail(selectedShape?.id),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!(user?.token && selectedShape?.id) },
  });

  if (isError) {
    return <Typography>Failed to fetch data</Typography>;
  }

  if (isFetching || !data) {
    return (
      <Stack spacing={2.5}>
        <Skeleton variant='rectangular' width='100%' height={210} />
        <Skeleton variant='rectangular' width='100%' height={210} />
        <Skeleton variant='rectangular' width='100%' height={210} />
      </Stack>
    );
  }

  const { shape, actionsList, ...shapeActions } = data;

  return (
    <>
      {!isFetching && data?.shape && selectedShape ? (
        <ShapeDetailsForm
          shapeDetail={shape}
          user={user}
          graph={graph}
          workflowId={workflowId}
        />
      ) : null}

      {shape?.type === 'terminator' ? (
        <TerminatorActions
          workflowId={workflowId}
          shapeId={shape?.id}
          shapeDetail={shape}
          loadingShapeDetail={isFetching}
        />
      ) : shape?.type === 'delay' ? (
        <DelayActions shapeDetail={shape} shapeId={shape?.id} />
      ) : shape?.type === 'loop_limit' ? null : (
        <ShapeActionTypeList
          shapeActions={shapeActions}
          actionsList={actionsList}
          shape={shape}
          workflowId={workflowId}
          workflowName={workflowName}
        />
      )}
    </>
  );
}
